@charset "UTF-8";

@import "settings";
@import "mixins";

/*****************************************************************************
Responsive Block
*****************************************************************************/
#Wrapper {
  position: relative;
  left: 0;
}
.responsive-block-open #Wrapper {
  left: 0;
  @include transition(all 0.6s ease);
}
#responsive-block {
  visibility: hidden;
  position: fixed;
  left: -400px;
  top: 0;
  z-index: 9999;
  width: 400px;
  height: 100%;
  background: $color-links;
  @include transition(all 0.6s ease);
  .lang {
    text-align:center;
    li {
      display:inline-block;
      a {
        display:inline-block;
        padding:13px 15px 12px;
        color:#eff2f7;
        line-height:20px;
        font-size:16px;
        font-weight:300;
        text-transform:uppercase;
        &:hover {
          color: $color-links;
        }
      }
      &.active a {
        font-weight:700;
      }
    }
  }
  nav li a {
    display:block;
    padding: 15px 30px;
    color: #fff;
    line-height: 20px;
    font-size: 18px;
    font-weight:300;
    @include transition(all 0.2s ease);
  }
  nav > ul > li > a:hover, nav > ul > li:hover > a, nav > ul > li.active > a {
    background-color: #f1607d;
    color: #fff;
  }
  .submenu .level-links {
    visibility:hidden;
    position:absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    background-color: $color-links;
    @include transition(all 0.6s ease);
    li {
      border-bottom: 1px solid #ffa4b6;
      a {
        color: #fff;
        @include transition(all 0.2s ease);
        &:hover {
          color: #ffa4b6;
        }
      }
    }
    .back {
      padding: 15px 30px;
      background: #f1607d url(../img/arrow-left-12x23.png) no-repeat 10px center;
      color: #fff;
      line-height: 20px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .bottom-social {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    background: $color-links;
    a {
      display:block;
      float:left;
      width:100%;
      height:50px;
      color: #fff;
      line-height:50px;
      font-size:17px;
      font-weight:500;
      text-align:center;
      @include transition(all 0.3s ease);
      &:hover {
        color: #fff;
      }
    }
  }
  .submenu.active .level-links {
    visibility:visible;
    left:0;
  }
}
.responsive-block-open #responsive-block {
  visibility: visible;
  left: 0;
  @include transition(all 0.6s ease);
}

/*****************************************************************************
Header
*****************************************************************************/
#Header {
  position: fixed;
  z-index: 1040;
  width: 100%;
  padding: 0 40px 0 73px;
  background-color: #fff;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
  .header-content {
    display: flex;
    justify-content: flex-end;
    .left-col {
      position: absolute;
      top: 0;
      left: 38px;
      @include transition(all 0.4s ease);
      a.logo {
        display:block;
        width: 398px;
        height: 335px;
        background: url(../img/logo.png) no-repeat 0 0;
        background-size: 100% auto;
        text-indent: -9999px;
        @include transition(all 0.4s ease);
      }
      &.logo-sb {
        top: -15px;
        a.logo {
          width: 331px;
          height: 263px;
        }
      }
    }
    .right-col {
      //flex: 1;
      width: 1500px;
      .top-bar {
        visibility: visible;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 70px;
        @include transition(all 0.4s ease);
        .phone {
          display: flex;
          p {
            margin: 0;
            &.tel {
              padding-left: 22px;
              background: url(../img/icons/tel.png) no-repeat 0 center;
              color: #080808;
              font-size: 17px;
              line-height: 17px;
              font-weight: 500;
              a {
                color: #080808;
              }
            }
            &.text {
              margin-right: 28px;
              color: #5b5b5b;
              font-size: 12px;
              line-height: 15px;
              font-weight: 500;
            }
          }
        }
        .email {
          margin-left: 53px;
          a {
            padding-left: 26px;
            background: url(../img/icons/email.png) no-repeat 0 center;
            color: #080808;
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
            @include transition(all 0.3s ease);
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .social-icon {
          display: flex;
          align-items: center;
          a {
            display: block;
            width: 27px;
            height: 26px;
            margin-left: 12px;
            text-indent: -9999px;
            @include transition(all 0.3s ease);
            &:first-child {
              margin-left: 41px;
            }
            &.fb {
              background: url(../img/icons/fb.png) no-repeat 0 0;
            }
            &.yt {
              background: url(../img/icons/yt.png) no-repeat 0 0;
            }
            &.in {
              background: url(../img/icons/in.png) no-repeat 0 0;
            }
          }
        }
      }
      .menu-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 86px;
        border-top: 1px solid #e5e5e5;
      }
    }
  }
}

#Header.sticky {
  .header-content {
    .left-col {
      /*padding-right: 0;
      padding-top: 0;
      display: flex;
      align-items: center;*/
      a.logo {
        width: 200px;
        height: 168px;
      }
      &.logo-sb {
        top: 0;
      }
    }
    .right-col {
      .top-bar {
        visibility: hidden;
        height: 0;
      }
      .menu-bar {
        border-top: 0;
      }
    }
  }
}

#menu {
  & > ul {
    display: flex;
    & > li {
      position: relative;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      & > a {
        display: block;
        padding: 0 25px;
        line-height: 85px;
        color: #222;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-links;
        }
      }
      &:last-child > a {
        padding-right: 0;
      }
      &.active > a {
        color: $color-links;
      }
      &:hover > a {
        color: $color-links;
      }
      .level-links {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 85px;
        right: -60px;
        z-index: 9;
        width: 300px;
        padding: 28px 35px;
        @include box-shadow(0 0 15px rgba(0, 0, 0, 0.3));
        background: #e01d3b;
        background: -webkit-linear-gradient(to right, #e3324d , #e01d3b);
        background: -o-linear-gradient(to right, #e3324d , #e01d3b);
        background: -moz-linear-gradient(to right, #e3324d , #e01d3b);
        background: linear-gradient(to right, #e3324d , #e01d3b);
        @include transition(all 0.3s ease);
        .links li {
          text-transform: none;
          a {
            display: flex;
            padding: 8px 0;
            color: #fff;
            font-size: 15px;
            line-height: 17px;
            font-weight: 500;
            &:hover {
              color: #ff8598;
            }
            &:before {
              content: "";
              float: left;
              margin: 7px 15px 0 0;
              width: 4px;
              height: 4px;
              background-color: #fff;
              @include border-radius(4px);
              text-shadow: 1px 1px #b6040b;
            }
            &:hover:before {
              background-color: #ff8598;
            }
          }
        }
      }
      &:hover {
        .level-links {
          visibility: visible;
          opacity: 1;
        }
      }
      &.submenu {
        &:after {
          visibility: hidden;
          opacity: 0;
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #e01d3b;
        }
        &:hover {
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#nav-icon span {
  background-color: #000;
}

/*****************************************************************************
Content
*****************************************************************************/
#Content {
  padding-top: 156px;
}

.text-line {
  display: flex;
  align-items: center;
  h3 {
    margin-right: 24px;
    color: #222;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
  }
  .line {
    margin-top: 5px;
    flex: 1;
    height: 1px;
    background-color: #e0e0e0;
  }
}

h3.line {
  color: #292a2e;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  &:after {
    content: '';
    display: block;
    margin: 13px auto 0;
    width: 226px;
    height: 2px;
    background: #e2001a;
  }
  a {
    color: #292a2e;
    &:hover {
      color: $color-links;
    }
  }
}

#slider-main-content {
  position: relative;
  .slider-main {
    .slide {
      position: relative;
      .photo {
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      .content-wrapper {
        margin: 0 auto;
        width: 1400px;
        position: absolute;
        top: 165px;
        left: 0;
        right: 0;
        .desc {
          width: 740px;
          h2 {
            margin-bottom: 36px;
            color: #fff;
            font-weight: 700;
            font-size: 60px;
            line-height: 60px;
            text-shadow: 1px 0 3px rgba(1, 1, 1, 0.2);
            .yellow {
              color: #ffdd18;
            }
          }
          p {
            margin-bottom: 29px;
            color: #fff;
            font-weight: 400;
            font-size: 26px;
            text-shadow: 1px 0 3px rgba(1, 1, 1, 0.2);
          }
          a.btn-more {
            display: inline-block;
            padding: 0 60px;
            height: 61px;
            line-height: 61px;
            color: #fff;
            border: 2px solid #fbfbfb;
            font-size: 17px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 30px;
            @include transition(all 0.3s ease);
            &:hover {
              background: #69903b;
              border-color: #69903b;
            }
          }
        }
      }
    }
  }
  .bx-controls {
    position: absolute;
    top: 437px;
    left: 0;
    right: 0;
    z-index: 88;
    width: 44px;
    margin: 0 auto;
    .bx-pager {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-left: 720px;
      .bx-pager-item {
        .bx-pager-link {
          display: block;
          margin-bottom: 9px;
          width: 22px;
          height: 3px;
          background-color: rgba(255,255,255,0.5);
          text-indent: -9999px;
          @include border-radius(1px);
          @include transition(all 0.3s ease);
          &.active {
            width: 44px;
            background-color: rgba(255,255,255,1);
          }
        }
      }
    }
  }
}

#animation-offer {
  position: relative;
  margin-top: -141px;
  z-index: 999;
  .animation-offer__content {
    position: relative;
    height: 141px;
    border-top: 1px solid rgba(255,255,255,0.5);
    .active-item {
      position: absolute;
      left: 0;
      top: -2px;
      width: 203px;
      height: 5px;
      background-color: $color-links;
      @include transition(all 0.5s ease);
      &.position1 {
        left: 0;
      }
      &.position2 {
        left: 245px;
      }
      &.position3 {
        left: 465px;
      }
      &.position4 {
        left: 698px;
      }
      &.position5 {
        left: 940px;
      }
      &.position6 {
        left: 1243px;
        width: 158px;
      }
    }
    .items {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
    .item {
      height: 100%;
      @include transition(all 0.3s ease);
      a {
        display: flex;
        align-items: center;
        text-align: left;
        height: 100%;
        color: #fff;
        font-size: 17px;
        line-height: 17px;
        font-weight: 600;
        text-shadow: 1PX 0 3px rgba(1, 1, 1, 0.3);
        &:hover {
          color: $color-links;
        }
      }
    }
  }
}

#offer-home {
  margin-top: 84px;
  &.subpage {
    margin-top: 64px;
  }
  .items {
    display: flex;
    justify-content: space-between;
    .item {
      position: relative;
      width: 678px;
      height: 323px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 75px 0 0 50px;
      }
      h2 {
        margin-bottom: 8px;
        color: #fff;
        font-size: 48px;
        line-height: 48px;
        font-weight: 700;
        text-shadow: 0 0 5px rgba(24, 23, 24, 0.3);
      }
      p {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
      }
      .btn-white {
        position: absolute;
        left: 50px;
        bottom: 39px;
      }
      .photo {
        position: absolute;
        top: 0;
        right: 0;
      }
      &.offer-blue {
        background: url(../img/bg-blue.jpg) no-repeat 0 0;
        p {
          color: #085971;
        }
        .btn-white:hover {
          background-color: #2a9aba;
        }
        .photo {
          width: 356px;
          height: 323px;
          background: url(../img/fotobudka.png) no-repeat 0 0;
        }
      }
      &.offer-yellow {
        background: url(../img/bg-yellow.jpg) no-repeat 0 0;
        p {
          color: #917210;
        }
        .btn-white:hover {
          background-color: #d6a915;
        }
        .photo {
          width: 410px;
          height: 323px;
          background: url(../img/bubble-show.png) no-repeat 0 0;
        }
      }
    }
  }
  .offer-pink {
    position: relative;
    margin-top: 51px;
    width: 100%;
    height: 401px;
    background: url(../img/bg-pink.jpg) no-repeat 0 0;
    a {
      display: block;
      height: 100%;
    }
    .inside {
      margin-left: 140px;
      padding-top: 64px;
      width: 470px;
      text-align: center;
    }
    h2 {
      margin-bottom: 15px;
      margin-left: 20px;
      width: 400px;
      height: 146px;
      background: url(../img/animacje-text.png) no-repeat 0 0;
      text-indent: -9999px;
    }
    p {
      margin-bottom: 20px;
      color: #fff;
      font-size: 19px;
      line-height: 21px;
      font-weight: 500;
      text-shadow: 0 0 5px rgba(24, 23, 24, 0.2);
    }
    .btn-white:hover {
      background-color: #c5355c;
    }
    .photo {
      position: absolute;
      top: 0;
      right: 64px;
      width: 635px;
      height: 401px;
      background: url(../img/animacje.png) no-repeat 0 0;
    }
  }
}

#about-us {
  margin-top: -162px;
  height: 914px;
  background: url(../img/rabbit-bg.png) no-repeat 0 0;
  .inside {
    display: flex;
    justify-content: flex-end;
    padding-top: 352px;
    .right-col {
      width: 702px;
    }
    h3 {
      margin-bottom: 34px;
      padding-bottom: 14px;
      border-bottom: 2px solid #e2001a;
      color: #333;
      font-size: 30px;
      font-weight: 400;
    }
    p {
      margin-bottom: 20px;
      color: #666;
      font-size: 17px;
      line-height: 21px;
      font-weight: 300;
    }
  }
  .bottom {
    display: none;
  }
}

#blog-home {
  padding-top: 60px;
  .blog-content {
    padding-left: 157px;
  }
  h3 {
    margin-bottom: 71px;
  }
  .items {
    display: flex;
    .item {
      width: 369px;
      margin-right: 35px;
      a {
        .photo {
          margin-bottom: 32px;
          width: 369px;
          height: 226px;
          overflow: hidden;
          img {
            display: block;
            height: 100%;
            margin: 0 auto;
            @include transition(all 0.4s ease);
          }
        }
        h5 {
          margin-bottom: 16px;
          font-size: 17px;
          line-height: 27px;
          font-weight: 500;
          color: #333;
          @include transition(all 0.3s ease);
        }
        .date {
          color: #ababad;
          font-size: 13px;
          font-weight: 500;
        }
        &:hover {
          .photo {
            img {
              @include transform(scale(1.1));
              @include transition(all 0.4s ease);
            }
          }
          h5 {
            color: $color-links;
          }
        }
      }
    }
    .more {
      flex: 1;
      height: 226px;
      background-color: #3f69be;
      @include transition(all 0.4s ease);
      a {
        display: flex;
        align-items: center;
        padding-left: 84px;
        height: 100%;
        color: #fff;
        font-size: 19px;
        font-weight: 500;
      }
      &:hover {
        background-color: #4b7bdd;
      }
    }
  }
}

#contact-home {
  position: relative;
  margin-top: 101px;
  height: 200px;
  background: url(../img/contact-home.png) no-repeat center top;
  .inside {
    display: flex;
    .left-col {
      width: 50%;
      padding-top: 54px;
      padding-right: 97px;
      text-align: right;
      p {
        margin-bottom: 0;
        color: #fff;
        font-weight: 300;
        font-size: 17px;
      }
      h4 {
        color: #fff;
        font-weight: 700;
        font-size: 60px;
      }
    }
    .right-col {
      width: 50%;
      padding-top: 27px;
      padding-left: 37px;
      h5 {
        color: #dc1b3a;
        font-weight: 300;
        font-size: 34px;
      }
    }
  }
}

#gallery-home {
  margin-top: -100px;
  padding-top: 198px;
  background: url(../img/bg-grey.png) no-repeat center 0;
  &.subpage {
    margin-top: 0;
    margin-bottom: 150px;
    padding: 0;
    background: none;
    .items {
      margin-top: 0;
    }
  }
  .items {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    .item {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(2n){
        margin-top: 66px;
      }
      a {
        position: relative;
        display: block;
        width: 476px;
        height: 363px;
        overflow: hidden;
        @include transition(all 0.7s ease);
        @include box-shadow(21px 0 65px rgba(24, 23, 24, 0.28));
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          @include transition(all 0.7s ease);
        }
        span {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: calc(50% - 27px);
          left: 0;
          right: 0;
          width: 200px;
          margin: 0 auto;
          text-align: center;
          background: rgba(0, 0, 0, 0.6);
          padding: 15px 20px;
          color: #fff;
          font-size: 16px;
          @include transition(all 0.4s ease);
        }
        .border {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 2px solid #cac5c5;
        }
        &:hover {
          img {
            @include transform(scale(1.1));
            @include transition(all 0.7s ease);
          }
          span {
            visibility: visible;
            opacity: 1;
            &:hover {
              background-color: $color-links;
            }
          }
          .border {
            opacity: 1;
            margin: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
          }
        }
      }
    }
  }
}

#opinions-home {
  margin-top: 114px;
  .inside {
    padding-bottom: 113px;
    border-bottom: 1px solid #dedfdf;
  }
  h3 {
    margin-bottom: 9px;
  }
  p {
    margin-bottom: 55px;
    color: #757575;
    font-size: 17px;
    font-weight: 300;
    text-align: center;
  }
  .items {
    display: flex !important;
    justify-content: center;
    .item {
      width: 33.3%;
      margin-right: 45px;
      padding: 3px 51px 10px 51px;
      border-right: 1px solid #f1f1f1;
      background: url(../img/icons/quote.png) no-repeat 0 10px;
      &:last-child {
        border-right: 0;
      }
      p.txt {
        margin-bottom: 34px;
        color: #757575;
        font-size: 17px;
        line-height: 28px;
        font-weight: 300;
        text-align: left;
      }
      p.name {
        margin: 0;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        text-align: right;
      }
    }
  }
}

#partners {
  padding-top: 89px;
  h3 {
    margin-bottom: 73px;
    color: #292a2e;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }
  .inside {
    display: flex;
    justify-content: center;
  }
  .slider-partners-content {
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 113px;
      border: 1px solid #d4d7dc;
      @include border-radius(15px);
      img {
        display: block;
        max-width: 85%;
      }
    }
  }
}

#join-customers {
  margin-top: 114px;
  height: 592px;
  background: url(../img/join-customers.jpg) no-repeat center 85px;
  &.subpage {
    margin-top: 30px;
  }
  .text {
    height: 158px;
    background: url(../img/bg-green.png) no-repeat center 0;
    h3 {
      padding-top: 74px;
      color: #fff;
      font-size: 36px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      a {
        color: #fff;
        padding: 60px 212px;
        @include transition(all 0.4s ease);
        &:hover {
          color: #6d7413;
        }
      }
    }
  }
}

/*****************************************************************************
Footer
*****************************************************************************/
#Footer {
  padding: 12px 0 18px;
  background-color: #b8bb13;
  .social-links {
    color: #74760a;
    .inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      .social-icons {
        display: flex;
        align-items: center;
        p {
          margin: 0 13px 0 0;
          color: #74760a;
          font-weight: 400;
          text-transform: uppercase;
        }
        ul {
          display: flex;
          padding: 0;
          line-height: 25px;
          li {
            padding: 0 10px;
            a {
              font-size: 15px;
              color: #74760a;
              font-weight: 700;
              @include transition(all 0.3s ease);
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .links{
        a {
          font-size: 15px;
          font-weight: 400;
          color: #74760a;
          @include transition(all 0.3s ease);
          &:hover {
            text-decoration: underline;
          }
        }
        .space {
          display: inline-block;
          padding: 0 10px;
          color: #74760a;
        }
      }
    }
  }
  .menu {
    .inside {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 115px;
      border-top: 1px solid #cfd15f;
      border-bottom: 1px solid #cfd15f;
      .menu-list {
        display: flex;
        li {
          padding: 0 34px;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
          a {
            color: #fff;
            @include transition(all 0.3s ease);
            &:hover {
              color: $color-links;
            }
          }
        }
      }
    }
  }
  .signature {
    p {
      margin: 0;
      color: #74760a;
      font-size: 15px;
      font-weight: 400;
      br {
        display: none;
      }
    }
    .inside {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: #fff;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
      .text-right {
        a {
          font-weight: 500;
        }
      }
    }
  }
}

/*****************************************************************************
Subpages
*****************************************************************************/
.subpage-simple {
  margin: 50px 0 90px;
}

.subpage-blog {
  padding: 100px 0 50px;
  .inside {
    display: flex;
    flex-wrap: wrap;
    padding: 0 116px;
    .item {
      width: 369px;
      margin-bottom: 50px;
      margin-right: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      a {
        .photo {
          margin-bottom: 32px;
          width: 100%;
          height: 226px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
            @include transition(all 0.4s ease);
          }
        }
        h5 {
          margin-bottom: 16px;
          font-size: 17px;
          line-height: 27px;
          font-weight: 500;
          color: #333;
          @include transition(all 0.3s ease);
        }
        .date {
          color: #ababad;
          font-size: 13px;
          font-weight: 500;
        }
        &:hover {
          .photo {
            img {
              @include transform(scale(1.1));
              @include transition(all 0.4s ease);
            }
          }
          h5 {
            color: $color-links;
          }
        }
      }
    }
  }
}

.subpage-blog-details {
  margin: 70px 0 90px;
  .inside {
    display: flex;
    justify-content: space-between;
    .blog-details {
      width: 974px;
      .blog-details__header {
        margin-bottom: 50px;
        h3 {
          position: relative;
          margin-bottom: 5px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #333;
          font-size: 34px;
          line-height: 40px;
          font-weight: 500;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 189px;
            height: 3px;
            background-color: $color-links;
          }
        }
        .date {
          color: #969ca1;
          font-size: 14px;
          font-weight: 300;
        }
      }
      img {
        display: block;
        max-width: 100%;
        margin: 40px 0;
      }
    }
    .blog-sidebar {
      width: 374px;
      margin-top: 15px;
      aside {
        margin-bottom: 30px;
        h3 {
          margin-bottom: 43px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #222;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      .widget-latest-news {
        .items {
          .item a {
            display: flex;
            justify-content: space-between;
            margin-bottom: 19px;
            padding-bottom: 16px;
            border-bottom: 1px solid #f3f3f3;
            .photo {
              width: 85px;
              img {
                display: block;
                max-width: 100%;
                @include transition(all 0.3s ease);
              }
            }
            .desc {
              width: 272px;
              p {
                &.date {
                  margin-bottom: 6px;
                  color: #ababad;
                  font-size: 13px;
                  font-weight: 500;
                  text-transform: uppercase;
                }
                &.title {
                  margin: 0;
                  color: #414449;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 17px;
                }
              }
            }
            &:hover {
              .photo {
                img {
                  opacity: 0.9;
                }
              }
              .desc {
                p.title {
                  text-decoration: underline;
                }
              }
            }
          }
          .item:last-child a {
            border-bottom: 0;
          }
        }
      }
      .side-tags {
        .items {
          display: flex;
          flex-wrap: wrap;
          a {
            display: block;
            color: #888b91;
            font-size: 13px;
            font-weight: 500;
            padding: 10px 15px;
            background: #f3f3f3;
            margin: 0 8px 8px 0;
            @include border-radius(5px);
            @include transition(all 0.3s ease);
            &:hover {
              background: $color-links;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.subpage-gallery {
  margin: 100px 0 50px;
  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 25px;
      margin-right: 25px;
      &:nth-child(3n) {
        margin-right:0;
      }
      a {
        position: relative;
        display: block;
        width: 450px;
        height: 343px;
        overflow: hidden;
        @include transition(all 0.7s ease);
        @include box-shadow(21px 0 65px rgba(24, 23, 24, 0.28));
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          @include transition(all 0.7s ease);
        }
        span {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: calc(50% - 27px);
          left: 0;
          right: 0;
          width: 200px;
          margin: 0 auto;
          text-align: center;
          background: rgba(0, 0, 0, 0.6);
          padding: 15px 20px;
          color: #fff;
          font-size: 16px;
          @include transition(all 0.4s ease);
        }
        .border {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 2px solid #cac5c5;
        }
        &:hover {
          img {
            @include transform(scale(1.1));
            @include transition(all 0.7s ease);
          }
          span {
            visibility: visible;
            opacity: 1;
            &:hover {
              background-color: $color-links;
            }
          }
          .border {
            opacity: 1;
            margin: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
          }
        }
      }
    }
  }
}

.subpage-shop {
  margin: 100px 0 50px;
  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 437px;
      margin-bottom: 38px;
      margin-right: 44px;
      background-color: #f6f6f6;
      -webkit-border-bottom-right-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -moz-border-radius-bottomleft: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      @include transition(all 0.4s ease);
      &:nth-child(3n) {
        margin-right:0;
      }
      a {
        display: block;
        @include transition(all 0.4s ease);
        .photo {
          width: 437px;
          height: 310px;
          img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            -webkit-border-top-left-radius: 4px;
            -webkit-border-top-right-radius: 4px;
            -moz-border-radius-topleft: 4px;
            -moz-border-radius-topright: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            @include transition(all 0.7s ease);
          }
        }
        .desc {
          padding: 41px 15px 34px;
          text-align: center;
          h4 {
            color: #000;
            font-size: 27px;
            font-weight: 600;
          }
        }
      }
      &:hover {
        @include box-shadow(10px 0 25px rgba(24, 23, 24, 0.28));
      }
    }
  }
}

.subpage-product {
  margin: 90px 0 87px;
  .inside {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-col {
      width: 764px;
      .photo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 612px;
        border: 1px solid #e1e2e2;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
    .right-col {
      width: 587px;
      position: relative;
      h3 {
        margin-bottom: 30px;
        padding-bottom: 17px;
        border-bottom: 1px solid #b7b7b8;
        color: #000526;
        font-size: 28px;
        font-weight: 600;
      }
      .stars {
        margin-bottom: 30px;
        width: 162px;
        height: 27px;
        background: url(../img/stars.png) no-repeat 0 0;
        background-size: 100%;
      }
      p {
        color: #292a2e;
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .left {
          margin-right: 32px;
          p {
            margin-bottom: 7px;
            color: #9f9fa0;
            font-size: 15px;
            line-height: 22px;
            text-align: right;
            strong {
              color: #000526;
              font-weight: 600;
            }
          }
        }
        .right {
          width: 247px;
          .price {
            color: #8f959d;
            font-weight: 600;
            font-size: 17px;
            text-align: right;
            text-transform: uppercase;
            strong {
              color: #000526;
              font-size: 42px;
            }
            span {
              color: #000526;
            }
          }
        }
      }
    }
  }
  .photos {
    display: flex;
    .item {
      margin-right: 22px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 174px;
        border: 1px solid #e1e2e2;
        overflow: hidden;
        @include transition(all 0.4s ease);
        img {
          display: block;
          max-width: 100%;
          height: 138px;
          @include transition(all 0.4s ease);
        }
        &:hover {
          img {
            -webkit-filter: brightness(0.7);
            -moz-filter: brightness(0.7);
            -o-filter: brightness(0.7);
            -ms-filter: brightness(0.7);
            filter: brightness(0.7);
          }
        }
      }
    }
  }
}

.subpage-product-list {
  margin-bottom: 80px;
  h4 {
    margin-bottom: 33px;
    padding-bottom: 14px;
    border-bottom: 1px solid #b7b7b8;
    color: #111;
    font-size: 21px;
    line-height: 22px;
    font-weight: 500;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 259px;
      margin-bottom: 30px;
      margin-right: 26px;
      background-color: #f6f6f6;
      -webkit-border-bottom-right-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-radius-bottomright: 4px;
      -moz-border-radius-bottomleft: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      @include transition(all 0.4s ease);
      &:nth-child(5n) {
        margin-right:0;
      }
      a {
        display: block;
        @include transition(all 0.4s ease);
        .photo {
          width: 259px;
          height: 184px;
          img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            -webkit-border-top-left-radius: 4px;
            -webkit-border-top-right-radius: 4px;
            -moz-border-radius-topleft: 4px;
            -moz-border-radius-topright: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            @include transition(all 0.7s ease);
          }
        }
        .desc {
          padding: 22px 20px;
          text-align: center;
          h5 {
            color: #000;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      &:hover {
        @include box-shadow(10px 0 25px rgba(24, 23, 24, 0.28));
      }
    }
  }
}

.subpage-contact {
  margin: 92px 0;
  .inside {
    display: flex;
    .left-col {
      width: 566px;
      padding-right: 50px;
      border-right: 1px solid #d0d1d3;
      h3 {
        color: #232427;
        font-size: 24px;
        font-weight: 500;
      }
      h4 {
        margin-bottom: 50px;
        color: #727272;
        font-size: 17px;
        font-weight: 300;
      }
      p {
        color: #232427;
        font-size: 19px;
        font-weight: 400;
        a {
          color: #7dae11;
          text-decoration: underline;
        }
      }
      .social {
        margin-top: 40px;
        p {
          color: #999;
          font-size: 17px;
          font-weight: 400;
          text-transform: uppercase;
        }
        .items {
          display: flex;
          a {
            display: block;
            width: 54px;
            height: 54px;
            margin-right: 21px;
            text-indent: -9999px;
            @include transition(all 0.3s ease);
            &.fb {
              background: url(../img/icons/fb-big.png) no-repeat 0 0;
            }
            &.yt {
              background: url(../img/icons/yt-big.png) no-repeat 0 0;
            }
            &.in {
              background: url(../img/icons/in-big.png) no-repeat 0 0;
            }
          }
        }
      }
    }
    .right-col {
      width: 720px;
      padding-left: 66px;
      h3 {
        margin-bottom: 46px;
        color: #222;
        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
      }
    }
  }
}

.subpage-offer {
  margin-top: 70px;
  .points {
    margin-bottom: 135px;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 50px;
      li {
        position: relative;
        width: 538px;
        padding: 15px 0 15px 40px;
        border-bottom: 1px solid #ccc;
        color: #333;
        font-size: 19px;
        font-weight: 500;
        &:nth-child(2n){
          margin-left: 160px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 26px;
          left: 4px;
          display: block;
          width: 5px;
          height: 5px;
          background-color: $color-links;
          @include border-radius(50%);
        }
      }
    }
  }
  .subpage-offer-blue p {
    color: #333;
    font-size: 19px;
    line-height: 30px;
    font-weight: 300;
    text-align: center;
    &.blue {
      color: #41b9db;
      font-weight: 600;
    }
  }
  .subpage-offer-blue h5 {
    margin-top: 60px;
    margin-bottom: 18px;
    padding-bottom: 13px;
    border-bottom: 1px solid #ccc;
    color: #333;
    font-size: 19px;
    font-weight: 500;
  }
  .subpage-offer-yellow {
    margin-bottom: 100px;
    p {
      color: #333;
      font-size: 19px;
      line-height: 30px;
      font-weight: 300;
      text-align: center;
      &.yellow {
        color: #f9c53d;
        font-weight: 600;
        font-size: 21px;
      }
    }
    h3 {
      margin-top: 40px;
      margin-bottom: 40px;
      color: #333;
      font-size: 25px;
      font-weight: 600;
      &.yellow {
        color: #9e7c11;
        text-align: center;
      }
    }
    h4 {
      margin-top: 60px;
      margin-bottom: 18px;
      color: #333;
      font-size: 23px;
      font-weight: 600;
    }
    h5 {
      margin-top: 60px;
      margin-bottom: 18px;
      padding-bottom: 13px;
      border-bottom: 1px solid #ccc;
      color: #333;
      font-size: 19px;
      font-weight: 500;
    }
    ul.half {
      margin-bottom: 50px;
    }
  }

  ul.normal {
    margin-bottom: 90px;
    li {
      position: relative;
      padding: 15px 0 15px 40px;
      color: #333;
      font-size: 19px;
      font-weight: 300;
      &:before {
        content: '';
        position: absolute;
        top: 26px;
        left: 4px;
        display: block;
        width: 5px;
        height: 5px;
        background-color: $color-links;
        @include border-radius(50%);
      }
      strong {
        font-weight: 500;
      }
    }
  }

  ul.half {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 90px;
    li {
      position: relative;
      width: 48%;
      padding: 15px 0 15px 40px;
      color: #333;
      font-size: 19px;
      font-weight: 300;
      &:before {
        content: '';
        position: absolute;
        top: 26px;
        left: 4px;
        display: block;
        width: 5px;
        height: 5px;
        background-color: $color-links;
        @include border-radius(50%);
      }
      strong {
        font-weight: 500;
      }
    }
  }
}

.offer-sb-blue {
  margin-top: 64px;
  .inside {
    position: relative;
    padding-top: 100px;
    padding-left: 150px;
    height: 401px;
    background: url(../img/bg-blue-big.jpg) no-repeat 0 0;
    h2 {
      margin-bottom: 30px;
      color: #fff;
      font-size: 92px;
      line-height: 92px;
      font-weight: 700;
      text-shadow: 0 0 5px rgba(24, 23, 24, 0.3);
    }
    p {
      margin: 0;
      color: #085971;
      font-size: 29px;
      font-weight: 400;
    }
    .photo {
      position: absolute;
      top: 0;
      right: 183px;
      width: 427px;
      height: 401px;
      background: url(../img/fotobudka2.png) no-repeat 0 0;
    }
  }
}

.offer-sb-yellow {
  margin-top: 64px;
  .inside {
    position: relative;
    padding-top: 100px;
    padding-left: 150px;
    height: 401px;
    background: url(../img/bg-yellow-big.jpg) no-repeat 0 0;
    h2 {
      margin-bottom: 30px;
      color: #fff;
      font-size: 92px;
      line-height: 92px;
      font-weight: 700;
      text-shadow: 0 0 5px rgba(24, 23, 24, 0.3);
    }
    p {
      margin: 0;
      color: #917210;
      font-size: 29px;
      font-weight: 400;
    }
    .photo {
      position: absolute;
      top: 0;
      right: 0;
      width: 499px;
      height: 401px;
      background: url(../img/bubble-show2.png) no-repeat 0 0;
    }
  }
}

.subpage-about-us {
  margin-top: 93px;
  .my-row {
    margin-bottom: 100px;
    .items {
      display: flex;
      justify-content: space-between;
      .photo {
        position: relative;
        width: 577px;
        height: 433px;
        &:before {
          position: absolute;
          top: -25px;
          right: -34px;
          content: '';
          display: block;
          width: 170px;
          height: 161px;
          background-color: #ccb1ab;
        }
        &:after {
          position: absolute;
          bottom: -34px;
          left: -17px;
          content: '';
          display: block;
          width: 170px;
          height: 161px;
          background-color: #e0d6fc;
        }
        img {
          position: absolute;
          z-index: 99;
          display: block;
          max-width: 100%;
        }
      }
      .desc {
        width: 703px;
        h3 {
          margin-top: 20px;
          margin-bottom: 15px;
          color: #222;
          font-size: 30px;
          line-height: 30px;
          font-weight: 400;
        }
        ul {
          li {
            position: relative;
            padding: 15px 0 15px 36px;
            color: #222;
            font-size: 17px;
            line-height: 21px;
            font-weight: 300;
            &:before {
              content: '';
              position: absolute;
              top: 23px;
              left: 4px;
              display: block;
              width: 5px;
              height: 5px;
              background-color: #9cc811;
              @include border-radius(50%);
            }
          }
        }
        p {
          margin-bottom: 30px;
          color: #222;
          font-size: 17px;
          line-height: 30px;
          font-weight: 300;
        }
      }
    }
    & > h5 {
      margin-bottom: 40px;
      color: #999;
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;
    }
    .quote {
      margin-top: 40px;
      color: #29899b;
      font-size: 19px;
      line-height: 30px;
      font-weight: 300;
      font-style: italic;
      text-align: center;
    }
    .photos {
      display: flex;
      justify-content: space-between;
      .item {
        width: 428px;
        height: 321px;
        a {
          display: block;
          img {
            display: block;
            max-width: 100%;
          }
        }
      }
    }
  }
}
